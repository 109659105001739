
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import globalConfig from "src/config/index";

@Component
export default class Contacts extends Vue {
  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 订单id
   * @private
   * @returns number
   */
  private get orderId(): any {
    if (this.$route.query && this.$route.query.id) {
      return this.$route.query.id + "";
    }

    return null;
  }

  /**
   * 取消服务订单
   * @protected
   * @returns void
   */
  protected async viewOrder() {
    this.$router.push({ name: "greenway-detail", query: { id: this.orderId } });
  }
}
