
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Field, Switch } from "vant";
import globalConfig from "src/config/index";
import { FileService, MedicalOrderService } from "src/services";
import { Toast } from "vant";
import dayjs from "dayjs";

@Component
export default class Reservation extends Vue {
  // 下拉popup
  private showPicker: any = {};

  // 时间日期
  private showDate: any = {};

  // 展开与折叠
  private activeNames: string[] = [];

  // 提交数据
  private formData: any = {};

  // 多选选中数据
  private selectedValues: any = [];

  // 数据模板
  private formModal: any = {};

  // 数据模板原数据
  private formProperties: any = [];

  /**
   * 是否显示加载中
   * @private
   *
   */
  private isLoading: Boolean = false;

  /**
   * 上传文件列表
   * @private
   * @returns boolean
   */
  private fileList: Array<any> = [];

  // 表单类型映射
  private fromItemType: any = {
    Text: 0,
    TextArea: 1,
    Number: 2,
    SingleOption: 3,
    MultipleOption: 4,
    Picture: 5,
    PictureList: 6,
    Date: 7,
    DateRange: 8,
    Time: 9,
    TimeRange: 10,
  };

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 订单id
   * @private
   * @returns number
   */
  private get orderId(): any {
    if (this.$route.query && this.$route.query.id) {
      return this.$route.query.id + "";
    }

    return null;
  }

  /**
   *  新增/编辑
   * @private
   * @returns any
   */
  private get isEdit(): any {
    if (this.$route.query && this.$route.query.type == "edit") {
      return true;
    }

    return false;
  }
  /**
   * 处理表单- 是否显示
   * @param item 行
   * @param condition 判断条件
   */
  private conditionShowChange(item: any) {
    let { condition } = item;
    var formData = this.formData;
    try {
      if (condition && condition.condition) {
        // str 为条件语句，如果满足条件，则用condition里面的属性
        if (eval(condition.condition.replaceAll("$this.", "formData."))) {
          return condition.clientShow;
        }
      } else {
        return item.clientShow;
      }
    } catch (error) {
      return item.clientShow;
    }
  }

  /**
   * 处理表单- 是否必填
   * @param item 行
   * @param condition 判断条件
   */
  private requiredValidate(item: any) {
    let { condition } = item;
    var formData = this.formData;
    try {
      if (condition && condition.condition) {
        // str 为条件语句，如果满足条件，则用condition里面的属性
        if (eval(condition.condition.replaceAll("$this.", "formData."))) {
          return condition.clientRequired;
        }
      } else {
        return item.clientRequired;
      }
    } catch (error) {
      return item.clientRequired;
    }
  }

  /**
   * 处理表单- 校验
   * @param item 行
   * @param regexRule 正则校验
   */
  private rulesValidate(item: any) {
    let { regexRule } = item;
    let rules: any = [{ required: this.requiredValidate(item), message: item.placeholder }];
    if (regexRule) {
      rules.push({ pattern: new RegExp(regexRule.pattern), message: regexRule.errorMessage });
    }
    return rules;
  }

  /**
   * 格式化数据
   * @private
   * @returns void
   */
  protected formateFormModal(modal): any {
    const map = new Map();
    const result: any = [];
    for (const item of modal) {
      if (!map.has(item.group)) {
        map.set(item.group, true);
        result.push({
          group: item.group,
          groupExpanded: true,
          list: [],
        });
        item.groupExpanded && this.activeNames.push(item.group);
      }
    }
    let obj = {};
    for (const groupItem of result) {
      for (const item of modal) {
        obj[item.propertyName] = false;
        if (item.group === groupItem.group) {
          groupItem.list.push(item);
        }
      }
    }
    // 存储单选弹窗picker
    this.showPicker = obj;
    // 存储日期弹窗picker
    this.showDate = obj;
    this.formModal = result;
  }

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    if (this.isEdit) {
      this.getMedicalOrderDetail();
    } else {
      this.getFormModal();
    }
  }

  /**
   * 获取模板
   * @protected
   * @returns void
   */
  protected async getFormModal() {
    try {
      let { content: result } = await MedicalOrderService.instance.getOrderProductForm(this.orderId);
      if (result.data) {
        // 预约信息数据处理
        this.formProperties = result.data.formProperties;
        this.formateFormModal(this.formProperties);
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 获取订单详情
   * @protected
   * @returns void
   */
  protected async getMedicalOrderDetail(blo: boolean = false) {
    try {
      let { content: result } = await MedicalOrderService.instance.getMedicalOrderDetail(this.orderId);

      if (result.data) {
        // 预约信息数据处理
        let appointmentInfo: any = {};
        this.formProperties = result.data.formProperties;
        this.formateFormModal(this.formProperties);
        this.formProperties.forEach((item) => {
          switch (item.type) {
            case this.fromItemType.Text:
            case this.fromItemType.TextArea:
            case this.fromItemType.Number:
            case this.fromItemType.Date:
            case this.fromItemType.DateRange:
            case this.fromItemType.Time:
            case this.fromItemType.TimeRange:
              appointmentInfo[item.propertyName] = item.answer;
              break;
            case this.fromItemType.SingleOption:
              let selectedOption = item.selectionOptions.find((i) => i.value == item.answer) || {};
              appointmentInfo[item.propertyName] = selectedOption.value || null;
              let key = item.propertyName + "Str";
              appointmentInfo[key] = selectedOption.name || null;
              break;
            case this.fromItemType.PictureList:
              appointmentInfo[item.propertyName] = item.answer;
              this.fileList = item.extras
                ? item.extras.map((i) => {
                    return {
                      url: i.fullPath,
                    };
                  })
                : [];
              break;
            default:
              appointmentInfo[item.propertyName] = item.answer;
              break;
          }
        });
        this.formData = appointmentInfo;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  private onConfirm(propertyName, value, index) {
    this.formData[propertyName] = value.value;
    let key = propertyName + "Str";
    this.formData[key] = value.name;
    this.showPicker[propertyName] = false;
  }

  private onPopup(propertyName) {
    this.showPicker[propertyName] = true;
  }

  /**
   * 文件上传
   * @private
   * @returns void
   */
  private async afterRead(propertyName: any, file: any): Promise<void> {
    try {
      let fileList: any = [];
      if (this.formData[propertyName]) {
        fileList = this.formData[propertyName].split(",");
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append("files", file.file);
      formData.append("location", "2");
      let { content: res } = await FileService.instance.uploadFile(formData);
      if (res.data && res.data.length) {
        fileList.push(res.data[0].id);
        this.formData[propertyName] = fileList.join();
      }
    } catch (err) {
      Toast(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 删除文件
   * @private
   * @returns void
   */
  private async onDeleteFile(propertyName: any, file: any, currentFile): Promise<void> {
    let fileList: any = [];
    if (this.formData[propertyName]) {
      fileList = this.formData[propertyName].split(",");
    }
    fileList.splice(currentFile.index, 1);
    this.formData[propertyName] = fileList.join();
  }

  /**
   * 文件超出大小
   * @private
   * @returns void
   */
  private onOversize(file: any): void {
    Toast("图片超出限制大小,请重新调整后上传");
  }

  /**
   * 选择日期
   * @private
   * @returns void
   */
  private onDateConfirm(propertyName, date) {
    this.showDate[propertyName] = false;
    this.formData[propertyName] = `${dayjs(date).format("YYYY/MM/DD")}`;
  }

  /**
   * 选择日期范围
   * @private
   * @returns void
   */
  private onDateRangeConfirm(propertyName, date) {
    const [start, end] = date;
    this.showDate[propertyName] = false;
    this.formData[propertyName] = `${dayjs(start).format("YYYY/MM/DD")} ~ ${dayjs(end).format("YYYY/MM/DD")}`;
  }

  /**
   * 提交预约
   * @private
   * @returns void
   */
  private async submitAppointment() {
    let data = {
      id: this.orderId,
      formDatas: this.formData,
    };
    try {
      let { content: result } = await MedicalOrderService.instance.submitOrderProductForm(data);
      this.$router.push({ name: "greenway-submit-success", query: { id: this.orderId } });
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
